import React from "react"
import PropTypes from "prop-types"
import { ThemeProvider } from "styled-components"

import Header from "../header"
import Footer from "../footer"
import CookieConsent from "../cookie-consent"
import { StoreProvider } from "../../store"
import theme from "../../theme"

import "../../scss/fonts.scss"
import "../../scss/app.scss"

const Layout = ({ children, hideHeader, hideFooter }) => {
  return (
    <ThemeProvider theme={theme}>
      <StoreProvider>
        <React.Fragment>
          {!hideHeader && <Header />}

          <main>{children}</main>

          {!hideFooter && <Footer />}

          <CookieConsent />
        </React.Fragment>
      </StoreProvider>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  hideHeader: PropTypes.bool,
  hideFooter: PropTypes.bool,
}

export default Layout
