import type from "./sections/type"
import colors from "./sections/colors"
import animations from "./sections/animations"
import mq from "./sections/mq"
import grid from "./sections/grid"
import zindex from "./sections/zindex"
import misc from "./sections/misc"

const index = {
  ...type,
  ...colors,
  ...animations,
  ...mq,
  ...grid,
  ...zindex,
  ...misc,
}

export default index
