import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled, { css } from "styled-components"
import { rem } from "polished"

import Link from "../link"
import SvgSprite from "../svg-sprite"
import { ReactComponent as UmbrellaSvg } from "../../images/illustrations/umbrella.svg"

const socialMediaIcons = Object.entries({
  // 'search string': 'svg sprite icon id'
  facebook: `facebook-alt`,
  instagram: `instagram`,
  twitter: `twitter`,
  youtube: `youtube`,
})

const getSocialMediaIcon = (link) => {
  let iconId = null
  socialMediaIcons.forEach(([search, icon]) => {
    if (link.url.includes(search) || link.title.includes(search)) {
      iconId = icon
    }
  })
  return iconId
}

const Main = styled.header`
  padding: ${rem(60)} 0;
  color: ${(props) => props.theme.colorWhite};
  background-color: ${(props) => props.theme.colorPurpleDarkest};

  @media ${(props) => props.theme.xsmallDown} {
    padding: ${rem(30)} 0;
  }
`

const MainInner = styled.div`
  ${(props) => props.theme.gridContainer()};
  ${(props) => props.theme.gridGrid()};
  ${(props) => props.theme.gridGridGutterX()};
  ${(props) => props.theme.gridGridGutterY()};

  @media ${(props) => props.theme.largeDown} and ${(props) =>
      props.theme.xsmallUp} {
    ${(props) => props.theme.gridGridGutterY(40)};
  }

  @media ${(props) => props.theme.xsmallDown} {
    ${(props) => props.theme.gridGridGutterY(30)};
  }
`

const Col = styled.div`
  ${(props) =>
    props.illustration &&
    css`
      margin-left: auto;
    `}

  @media ${(props) => props.theme.largeUp} {
    ${(props) => props.theme.gridCell(3)};
  }

  @media ${(props) => props.theme.largeDown} and ${(props) =>
      props.theme.xsmallUp} {
    ${(props) => props.theme.gridCell(6)};
  }

  @media ${(props) => props.theme.xsmallDown} {
    ${(props) => props.theme.gridCell(12)};

    ${(props) =>
      props.illustration &&
      css`
        display: none;
      `}
  }
`

const Links = styled.div`
  h2 {
    margin-bottom: ${rem(20)};
    color: ${(props) => props.theme.colorOrangeLight};
  }

  li:not(:last-child) {
    margin-bottom: ${rem(10)};
  }

  a {
    display: block;

    &:hover {
      opacity: 0.8;
    }

    &:active {
      opacity: 0.6;
    }
  }

  ${(props) =>
    props.socialMedia &&
    css`
      a {
        padding-left: 2em;
        position: relative;
      }

      svg {
        width: 1.3em;
        height: 1.3em;
        position: absolute;
        top: 0;
        left: 0;
      }
    `}
`

const Illustration = styled.div`
  @media ${(props) => props.theme.largeUp} {
    text-align: right;
  }

  svg {
    width: 100%;
    max-width: ${rem(160)};
    display: inline-block;
  }
`

const Foot = styled.div`
  padding: ${rem(30)} 0;
  color: ${(props) => props.theme.colorPurpleDarkest};
  background-color: ${(props) => props.theme.colorOrangeLight};

  @media ${(props) => props.theme.xsmallDown} {
    padding: ${rem(20)} 0;
  }
`

const FootInner = styled.div`
  ${(props) => props.theme.gridContainer()};

  @media ${(props) => props.theme.mediumUp} {
    display: flex;
  }
`

const Logo = styled.div`
  @media ${(props) => props.theme.mediumUp} {
    margin-right: ${rem(30)};
  }

  a {
    display: block;

    &:hover,
    &:focus {
      opacity: 0.7;
    }

    &:active {
      opacity: 0.5;
    }
  }

  img {
    width: ${rem(65)};
    height: ${rem(35)};
    display: block;
    object-fit: contain;

    @media ${(props) => props.theme.mediumDown} {
      margin: 0 auto;
    }
  }
`

const PolicyLinks = styled.ul`
  font-size: ${rem(14)};

  @media ${(props) => props.theme.mediumUp} {
    margin-right: ${rem(30)};
  }

  @media ${(props) => props.theme.mediumDown} {
    text-align: center;
    &:not(:first-child) {
      margin-top: ${rem(20)};
    }
  }

  li {
    display: inline-block;

    @media ${(props) => props.theme.mediumUp} {
      &:not(:last-child) {
        margin-right: 1.25em;
      }
    }

    @media ${(props) => props.theme.mediumDown} {
      margin: 0.25em 0.5em;
    }
  }

  a {
    &:hover {
      opacity: 0.7;
    }
    &:active {
      opacity: 0.5;
    }
  }
`

const FooterCopy = styled.div`
  font-size: ${rem(14)};
  max-width: ${rem(380)};

  @media ${(props) => props.theme.mediumDown} {
    margin: 0 auto;
  }

  h6 {
    margin-bottom: ${rem(8)};
  }

  @media ${(props) => props.theme.mediumUp} {
    margin-right: ${rem(30)};
  }

  @media ${(props) => props.theme.mediumDown} {
    text-align: center;

    &:not(:first-child) {
      margin-top: ${rem(20)};
    }
  }

  a {
    display: inline-block;
    margin-top: ${rem(8)};
    text-decoration: underline;

    &:hover {
      opacity: 0.7;
    }

    &:active {
      opacity: 0.5;
    }
  }
`

const ToTop = styled.div`
  margin-left: auto;
  font-size: ${rem(14)};

  @media ${(props) => props.theme.mediumDown} {
    text-align: center;

    &:not(:first-child) {
      margin-top: ${rem(20)};
    }
  }

  a {
    &:hover {
      opacity: 0.7;
    }

    &:active {
      opacity: 0.5;
    }
  }

  svg {
    width: 1.5em;
    height: 1.5em;
    margin-left: 0.75em;
    vertical-align: middle;
  }
`

const Footer = () => {
  const {
    wordpressAcfOptions: {
      options: {
        primaryNavTitle,
        primaryNavLinks,
        secondaryNavTitle,
        secondaryNavLinks,
        socialNavTitle,
        socialNavLinks,
        policyLinks,
        footerCopyText,
        footerCopyTitle,
        footerCopyLink,
      },
    },
  } = useStaticQuery(graphql`
    query {
      wordpressAcfOptions {
        options {
          primaryNavTitle: footer_primary_navigation_title
          primaryNavLinks: footer_primary_navigation_links {
            link: footer_primary_navigation_links_item {
              target
              title
              url
            }
          }

          secondaryNavTitle: footer_secondary_navigation_title
          secondaryNavLinks: footer_secondary_navigation_links {
            link: footer_secondary_navigation_links_item {
              target
              title
              url
            }
          }

          socialNavTitle: footer_social_navigation_title
          socialNavLinks: footer_social_navigation_links {
            link: footer_social_navigation_links_item {
              target
              title
              url
            }
          }

          policyLinks: footer_policy_links {
            link: footer_policy_links_item {
              target
              title
              url
            }
          }

          footerCopyTitle: footer_copy_title
          footerCopyText: footer_copy_text
          footerCopyLink: footer_copy_link {
            target
            title
            url
          }
        }
      }
    }
  `)

  const hasPrimaryNav =
    primaryNavTitle && primaryNavLinks && primaryNavLinks.length

  const hasSecondaryNav =
    secondaryNavTitle && secondaryNavLinks && secondaryNavLinks.length

  const hasSocialNav = socialNavTitle && socialNavLinks && socialNavLinks.length

  return (
    <footer>
      {(hasPrimaryNav || hasSecondaryNav || hasSocialNav) && (
        <Main>
          <MainInner>
            {hasPrimaryNav && (
              <Col>
                <Links>
                  <h2 className="styled-h6">{primaryNavTitle}</h2>

                  <ul>
                    {primaryNavLinks.map(({ link }, i) => (
                      <li key={i}>
                        <Link to={link.url} target={link.target}>
                          {link.title}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </Links>
              </Col>
            )}

            {hasSecondaryNav && (
              <Col>
                <Links>
                  <h2 className="styled-h6">{secondaryNavTitle}</h2>

                  <ul>
                    {secondaryNavLinks.map(({ link }, i) => (
                      <li key={i}>
                        <Link to={link.url} target={link.target}>
                          {link.title}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </Links>
              </Col>
            )}

            {hasSocialNav && (
              <Col>
                <Links socialMedia={true}>
                  <h2 className="styled-h6">{socialNavTitle}</h2>

                  <ul>
                    {socialNavLinks.map(({ link }, i) => {
                      const icon = getSocialMediaIcon(link)
                      return (
                        <li key={i}>
                          <Link to={link.url} target={link.target}>
                            {icon && <SvgSprite id={icon} />}
                            {link.title}
                          </Link>
                        </li>
                      )
                    })}
                  </ul>
                </Links>
              </Col>
            )}

            <Col illustration={true}>
              <Illustration>
                <UmbrellaSvg />
              </Illustration>
            </Col>
          </MainInner>
        </Main>
      )}

      <Foot>
        <FootInner>
          <Logo>
            <Link to="https://bilda.nu" title="Bilda">
              <img src="/images/logos/bilda.svg" alt="Bilda" />
            </Link>
          </Logo>

          {footerCopyTitle && footerCopyText && (
            <FooterCopy>
              <h6
                className="styled-h4"
                dangerouslySetInnerHTML={{ __html: footerCopyTitle }}
              />

              <div dangerouslySetInnerHTML={{ __html: footerCopyText }} />

              <Link to={footerCopyLink.url} target={footerCopyLink.target}>
                {footerCopyLink.title}
              </Link>
            </FooterCopy>
          )}

          {policyLinks && policyLinks.length && (
            <PolicyLinks>
              {policyLinks.map(({ link }, i) => (
                <li key={i}>
                  <Link to={link.url} target={link.target}>
                    {link.title}
                  </Link>
                </li>
              ))}
            </PolicyLinks>
          )}

          <ToTop>
            <Link
              to="#"
              onClick={(e) => {
                e.preventDefault()
                window.scrollTo({
                  top: 0,
                  left: 0,
                  behavior: `smooth`,
                })
              }}
            >
              Till toppen
              <SvgSprite id="arrow-to-up" />
            </Link>
          </ToTop>
        </FootInner>
      </Foot>
    </footer>
  )
}

export default Footer
