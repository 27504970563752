import React from "react"
import PropTypes from "prop-types"

const SvgSprite = ({ id, title, filename, ...rest }) => {
  if (title) {
    if (!rest.title) rest.title = title
    if (!rest[`aria-label`]) rest[`aria-label`] = title
  } else {
    if (typeof rest[`aria-hidden`] === `undefined`) rest[`aria-hidden`] = true
  }

  const hash = __GATSBY_CUSTOM_HASH__ // eslint-disable-line

  return (
    <svg {...rest}>
      {title && <title>{title}</title>}

      <use xlinkHref={`/${filename || `sprite`}-${hash}.svg#${id}`}></use>
    </svg>
  )
}

SvgSprite.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  filename: PropTypes.string,
}

export default SvgSprite
