import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled, { css } from "styled-components"
import { rem } from "polished"
import { ReactComponent as MenuSvg } from "@oddcamp/bilda-common-assets/src/images/sprite/menu.svg"
import { ReactComponent as CloseSvg } from "@oddcamp/bilda-common-assets/src/images/sprite/close.svg"

import Link from "../link"
import Button from "../button"
import SvgSprite from "../svg-sprite"

const breakPoint = `xlarge`

const Head = styled.div`
  display: flex;
  align-items: center;

  @media ${(props) => props.theme[`${breakPoint}Up`]} {
    padding: ${rem(20)} 0;
    justify-content: space-between;
  }

  @media ${(props) => props.theme[`${breakPoint}Down`]} {
    padding: ${rem(10)} 0;
    flex-wrap: wrap;
  }
`

const Logo = styled.div`
  a {
    display: block;

    &:hover,
    &:focus {
      opacity: 0.8;
    }

    &:active {
      opacity: 0.6;
    }
  }

  img {
    width: ${rem(100)};
    height: ${rem(50)};
    display: block;
    object-fit: contain;

    @media ${(props) => props.theme.xlargeDown} {
      width: ${rem(60)};
      height: ${rem(30)};
    }
  }
`

const Toggle = styled.button.attrs({ type: `button` })`
  width: ${rem(32)};
  height: ${rem(32)};
  margin-left: auto;
  transition: transform 0.3s ${(props) => props.theme.easingDefault};

  @media ${(props) => props.theme[`${breakPoint}Up`]} {
    display: none;
  }

  &:hover,
  &:focus {
    transform: scale(1.2);
  }

  svg {
    width: 100%;
    height: 100%;
    display: block;
    animation: ${(props) => props.theme.animScaleIn} 0.3s
      ${(props) => props.theme.easingDefault};
  }
`

const Buttons = styled.div`
  @media ${(props) => props.theme[`${breakPoint}Down`]} {
    display: none;
    flex: 1 1 100%;
    margin-top: ${rem(20)};
    text-align: center;
  }

  > * {
    @media ${(props) => props.theme[`${breakPoint}Down`]} {
      margin: ${rem(5)};
    }

    &:not(:last-child) {
      @media ${(props) => props.theme[`${breakPoint}Up`]} {
        margin-right: ${rem(20)};
      }
    }
  }
`

const Nav = styled.nav`
  text-align: center;
  position: relative;

  @media ${(props) => props.theme[`${breakPoint}Down`]} {
    display: none;
  }

  &::before {
    content: "";
    width: 100%;
    height: 1px;
    position: absolute;
    top: 0.25em;
    left: 0;
    background-color: ${(props) => props.theme.colorPurpleDarker};
  }

  ul {
    @media ${(props) => props.theme[`${breakPoint}Up`]} {
      margin: 0 -0.5em;
      overflow-x: auto;
      white-space: nowrap;
      mask: linear-gradient(
        to right,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 1) ${rem(10)},
        rgba(0, 0, 0, 1) calc(100% - ${rem(10)}),
        rgba(0, 0, 0, 0) 100%
      );

      ${(props) =>
        props.spaceAround &&
        css`
          display: flex;
          justify-content: space-around;
        `}
    }

    @media ${(props) => props.theme[`${breakPoint}Down`]} {
      padding: 0.5em 0;
    }
  }

  li {
    @media ${(props) => props.theme[`${breakPoint}Up`]} {
      display: inline-block;
      white-space: nowrap;
    }
  }

  a {
    padding: 1em 0.5em;
    display: block;

    ${(props) =>
      !props.spaceAround &&
      css`
        padding: 1em 2em;
      `}

    @media ${(props) => props.theme[`${breakPoint}Down`]} {
      padding: 0.5em;
    }

    &:hover,
    &:focus {
      opacity: 0.8;
    }

    &:active {
      opacity: 0.6;
    }

    &.\\--active {
      position: relative;

      &::before {
        content: "";
        width: 0.5em;
        height: 0.5em;
        background-color: ${(props) => props.theme.colorWhite};
        border-radius: 50%;
        opacity: 0.4;

        @media ${(props) => props.theme[`${breakPoint}Up`]} {
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
        }

        @media ${(props) => props.theme[`${breakPoint}Down`]} {
          display: inline-block;
          position: relative;
          top: -0.075em;
          margin-right: 0.4em;
        }
      }
    }
  }
`

const Inner = styled.div`
  ${(props) => props.theme.gridContainer()};
`

const Container = styled.header`
  color: ${(props) => props.theme.colorWhite};
  background-color: ${(props) => props.theme.colorPurpleDarkest};

  ${(props) =>
    props.mobileActive &&
    css`
      ${Buttons},
      ${Nav} {
        display: block;
        animation: ${(props) => props.theme.animSlideInY(-20)} 0.3s
          ${(props) => props.theme.easingDefault};
      }
    `}
`

const Header = () => {
  const [mobileActive, setMobileActive] = useState(false)

  const {
    wordpressAcfOptions: {
      options: { cta, navigation },
    },
  } = useStaticQuery(graphql`
    query {
      wordpressAcfOptions {
        options {
          cta: header_cta {
            link: header_cta_link {
              target
              title
              url
            }
            color: header_cta_link_color
            icon: header_cta_link_icon
          }

          navigation: header_navigation {
            link: header_navigation_link {
              target
              title
              url
            }
          }
        }
      }
    }
  `)

  const routeChange = () => setMobileActive(false)

  useEffect(() => {
    window.addEventListener(`onRouteChange`, routeChange)
    return () => {
      window.removeEventListener(`onRouteChange`, routeChange)
    }
  }, [])

  return (
    <Container mobileActive={mobileActive}>
      <Inner>
        <Head>
          <Logo>
            <Link to="/" title={process.env.GATSBY_SITE_NAME}>
              <img
                src={`/images/logos/${process.env.GATSBY_SITE_KEY}-white.svg`}
                alt={process.env.GATSBY_SITE_NAME}
              />
            </Link>
          </Logo>

          <Toggle
            onClick={() => setMobileActive(!mobileActive)}
            title={mobileActive ? `Dölj menyn` : `Visa menyn`}
          >
            {mobileActive ? (
              <CloseSvg aria-label="Dölj menyn" />
            ) : (
              <MenuSvg aria-label="Visa menyn" />
            )}
          </Toggle>

          {cta && cta.length > 0 && (
            <Buttons>
              {cta.map(({ link, icon, color }, i) => (
                <Button
                  key={i}
                  to={link.url}
                  target={link.target}
                  opts={`soft i-left c-${color}`}
                >
                  {icon && <SvgSprite id={icon} />}

                  {link.title}
                </Button>
              ))}
            </Buttons>
          )}
        </Head>

        {navigation && navigation.length > 0 && (
          <Nav spaceAround={navigation.length >= 4}>
            <ul>
              {navigation.map(({ link }, i) => (
                <li key={i}>
                  <Link
                    to={link.url}
                    target={link.target}
                    activeClassName="--active"
                    partiallyActive={true}
                  >
                    {link.title}
                  </Link>
                </li>
              ))}
            </ul>
          </Nav>
        )}
      </Inner>
    </Container>
  )
}

export default Header
