import { keyframes } from "styled-components"
import { rem } from "polished"

// https://github.com/oddcamp/sass-utils/blob/master/src/_animations.scss
import easings from 'sass-extract-loader?{"plugins": ["sass-extract-js"]}!@oddcamp/sass-utils/src/_animations.scss'

easings.easingDefault = easings.easingOutCubic

const animFadeIn = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`

const animScaleIn = keyframes`
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
`

const animSlideInY = (pos = 20) => keyframes`
  0% {
    transform: translateY(${rem(pos)});
  }

  100% {
    transform: translateY(0);
  }
`

const animSlideInX = (pos = 20) => keyframes`
  0% {
    transform: translateX(${rem(pos)});
  }

  100% {
    transform: translateY(0);
  }
`

export default {
  ...easings,
  animFadeIn,
  animScaleIn,
  animSlideInY,
  animSlideInX,
}
