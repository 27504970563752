// https://github.com/oddcamp/bilda-common-assets/blob/master/src/scss/base/_misc.scss

// import misc from 'sass-extract-loader?{"plugins": ["sass-extract-js"]}!@oddcamp/bilda-common-assets/src/scss/base/_misc.scss'
//
// export default { ...misc }

import { rem } from "polished"

const misc = {
  brGlobal: rem(5),
  bsBox: `0 ${rem(2)} ${rem(4)} 0 rgba(0, 0, 0, 0.1);`,
}

export default { ...misc }
