// https://github.com/oddcamp/bilda-common-assets/blob/master/src/scss/base/_type.scss

import { css } from "styled-components"
import { capitalize } from "lodash"

import type from 'sass-extract-loader?{"plugins": ["sass-extract-js"]}!@oddcamp/bilda-common-assets/src/scss/base/_type.scss'

type.FFPrimary = (weight = `normal`) => css`
  font-family: ${(props) => props.theme.ffPrimary};
  font-weight: ${(props) => props.theme[`fwPrimary${capitalize(weight)}`]};
`

type.FFSecondary = (weight = `normal`) => css`
  font-family: ${(props) => props.theme.ffSecondary};
  font-weight: ${(props) => props.theme[`fwSecondary${capitalize(weight)}`]};
`

type.FFTertiary = () => css`
  font-family: ${(props) => props.theme.ffSecondary};
  font-weight: ${(props) => props.theme.fwTertiaryBold};
`

export default type
