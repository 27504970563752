// https://github.com/oddcamp/bilda-common-assets/blob/master/src/scss/components/_button.scss

import React from "react"
import PropTypes from "prop-types"

import Link from "../link"

const Button = ({ children, to, opts, ...rest }) => {
  rest.className = `button ${rest.className || ``}`

  if (opts) {
    const options = opts.split(` `).map((i) => `button--${i.trim()}`)
    rest.className = `${rest.className} ${options.join(` `)}`
  }

  if (to) {
    // link
    return (
      <Link to={to} {...rest}>
        {children}
      </Link>
    )
  } else {
    // button
    return <button {...rest}>{children}</button>
  }
}

Button.propTypes = {
  children: PropTypes.node,
  to: PropTypes.string,
  opts: PropTypes.string,
}

export default Button
