import React from "react"
import PropTypes from "prop-types"

import Meta from "../meta"

const normalizePageMeta = (wp) => {
  const { acf } = wp

  const lang = acf.lang || undefined
  const title = acf.title || wp.title || wp.name || undefined
  const titleOverridePattern = acf.titleOverridePattern || undefined
  const description =
    acf.description || wp.content || wp.description || undefined
  const socialImage =
    (acf.socialImage && acf.socialImage.localFile
      ? acf.socialImage.localFile.url
      : undefined) ||
    (wp.featured_media && wp.featured_media.localFile
      ? wp.featured_media.localFile.url
      : undefined) ||
    undefined

  const robotsNoIndex = acf.robotsNoIndex || undefined
  const robotsNoFollow = acf.robotsNoFollow || undefined

  const ogType = acf.ogType || undefined
  const ogTitle = acf.ogTitle || title || undefined
  const ogDescription = acf.ogDescription || description || undefined
  const ogImage =
    acf.ogImage && acf.ogImage.localFile
      ? acf.ogImage.localFile.url
      : socialImage

  const twitterTitle = acf.twitterTitle || title || undefined
  const twitterDescription = acf.twitterDescription || description || undefined
  const twitterCreator = acf.twitterCreator || undefined
  const twitterImage =
    acf.twitterImage && acf.twitterImage.localFile
      ? acf.twitterImage.localFile.url
      : socialImage

  return {
    lang,
    title,
    titleOverridePattern,
    description,
    socialImage,
    robotsNoIndex,
    robotsNoFollow,
    ogType,
    ogTitle,
    ogDescription,
    ogImage,
    twitterTitle,
    twitterDescription,
    twitterImage,
    twitterCreator,
  }
}

const MetaWp = ({ wp, global, defaults, ...props }) => {
  const globalMeta = { ...global } // deep-copy

  globalMeta.socialImage =
    globalMeta.socialImage && globalMeta.socialImage.localFile
      ? globalMeta.socialImage.localFile.url
      : undefined

  globalMeta.ogImage =
    globalMeta.ogImage && globalMeta.ogImage.localFile
      ? globalMeta.ogImage.localFile.url
      : undefined

  globalMeta.twitterImage =
    globalMeta.twitterImage && globalMeta.twitterImage.localFile
      ? globalMeta.twitterImage.localFile.url
      : undefined

  const pageMeta = wp ? normalizePageMeta(wp) : {}

  Object.keys(pageMeta).forEach(
    (key) => pageMeta[key] === undefined && delete pageMeta[key]
  )

  return <Meta {...globalMeta} {...pageMeta} defaults={defaults} {...props} />
}

MetaWp.propTypes = {
  wp: PropTypes.object,
  options: PropTypes.object,
  global: PropTypes.object.isRequired,
  defaults: PropTypes.object.isRequired,
}

export default MetaWp
